<template>
  <ul class="cates">
    <li
      v-for="(item, index) in cates"
      :key="index"
      @click="queryCateData(item)"
    >
      <van-image lazy-load round :src="item.icon" />
      <p class="cate_name">{{ item.name }}</p>
    </li>
  </ul>
</template>

<script>
import { getSubCategorty } from '@/api/home'
export default {
  props: {
    cates: {
      type: Array
    }
  },
  methods: {
    async queryCateData (item) {
      if (item.url) {
        return this.$router.push('vip')
      }
      const res = await getSubCategorty(item.id)
      if (res.errorCode === 0) {
        const sub = res.data.sub
        if (sub && sub.length) return this.$router.push(`category/${item.id}`)
        this.$router.push({
          path: '/grid',
          query: {
            id: item.id
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cates {
  display: flex;
  direction: rtl;
  background-color: #fff;
  flex-wrap: wrap;
  // margin-top: 10px;
  padding: 10px 0 20px;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    text-align: center;
    margin: 20px 0 0;
    /deep/ .van-image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
    .cate_name {
      display: block;
      font-size: 28px;
      text-align: center;
      margin-top: 14px;
    }
  }
}
</style>
